import { Injectable } from '@angular/core';
import {
  DonorIdentityAuthClientResponseDto,
  InformationControllerService,
  TenantConfigurationControllerService,
  TenantIdService,
  TenantService,
} from '@hae/api';
import { map, Observable, shareReplay, take } from 'rxjs';

const TENANT_ID_STORE_KEY = 'tenantId';

@Injectable({ providedIn: 'root' })
export class TenantStateService {
  stateName = 'Tenant State';

  private auth?: Observable<DonorIdentityAuthClientResponseDto>;

  private configuration?: Observable<Record<string, string>>;

  private name?: Observable<string>;

  constructor(
    private tenantIdService: TenantIdService,
    private informationControllerService: InformationControllerService,
    private tenantConfigurationControllerService: TenantConfigurationControllerService,
    private tenantService: TenantService,
  ) {
    this.getId();
  }

  getAuth(): Observable<DonorIdentityAuthClientResponseDto> {
    if (!this.auth) {
      this.auth = this.tenantService.getAuthClients().pipe(
        map(({ payload }) => payload),
        shareReplay(1),
        take(1),
      );
    }
    return this.auth;
  }

  getConfiguration(): Observable<Record<string, string>> {
    if (!this.configuration) {
      this.configuration = this.tenantConfigurationControllerService
        .findPublicByCurrentTenant()
        .pipe(shareReplay(1), take(1));
    }
    return this.configuration;
  }

  getName(): Observable<string> {
    if (!this.name) {
      this.name = this.informationControllerService.getCurrentTenant().pipe(
        map(({ name }) => name),
        shareReplay(1),
        take(1),
      );
    }
    return this.name;
  }

  getGenderFlagEnabled(): Observable<boolean> {
    return this.getConfiguration().pipe(
      map(
        ({ donorLookupGenderEnabled }) => donorLookupGenderEnabled === 'true',
      ),
    );
  }

  isNewDonorFeature(): Observable<boolean> {
    return this.getConfiguration().pipe(
      map(({ newDonorFeature }) => newDonorFeature === 'true'),
    );
  }

  isAcknowledgementExpirationEnabled(): Observable<boolean> {
    return this.getConfiguration().pipe(
      map(
        ({ acknowledgementExpirationEnabled }) => acknowledgementExpirationEnabled === 'true',
      ),
    );
  }

  disableQnrForNewDonor(): Observable<boolean> {
    return this.getConfiguration().pipe(
      map(({ disableQnrForNewDonor }) => disableQnrForNewDonor === 'true'),
    );
  }

  getNewDonorSiteId(): Observable<string> {
    return this.getConfiguration().pipe(
      map(({ newDonorSiteId }) => newDonorSiteId),
    );
  }

  getNewDonorDays(): Observable<number> {
    return this.getConfiguration().pipe(
      map(({ newDonorDays }) => Number(newDonorDays) || 3),
    );
  }

  private getId(): void {
    const params = new URL(window.location.href).searchParams;
    const tenantId = params.get('tenantId');
    if (tenantId) {
      this.setId(tenantId);
      return;
    }

    const item = sessionStorage.getItem(TENANT_ID_STORE_KEY);
    if (item) {
      this.tenantIdService.tenantId.next(item);
    } else {
      this.tenantIdService.tenantId.next('');
    }
  }

  private setId(tenantId: string): void {
    sessionStorage.setItem(TENANT_ID_STORE_KEY, tenantId);
    this.tenantIdService.tenantId.next(tenantId);
  }
}
