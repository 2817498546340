import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  Renderer2,
} from '@angular/core';
import { TenantStateService } from '@hae/state';

@Component({
  selector: 'hae-google-tag',
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class GoogleTagComponent implements OnInit {
  constructor(
    private renderer: Renderer2,
    private tenantStateService: TenantStateService,
  ) {}

  ngOnInit(): void {
    this.tenantStateService
      .getConfiguration()
      .subscribe(({ googleAnalyticsContainerId }) => {
        const head = document.querySelector('head');
        const scriptElement = this.renderer.createElement('script');
        scriptElement.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(), event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${googleAnalyticsContainerId}');`;
        this.renderer.appendChild(head, scriptElement);
      });
  }
}
