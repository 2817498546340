import { AsyncPipe } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  signal,
} from '@angular/core';
import {
  NonNullableFormBuilder,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { ActivatedRoute } from '@angular/router';
import { CognitoService } from '@hae/auth';
import { TenantStateService } from '@hae/state';
import { DialogComponent } from '@hae/utils';
import { TranslateModule } from '@ngx-translate/core';
import { CognitoUserAttribute } from 'amazon-cognito-identity-js';
import { mergeMap } from 'rxjs';

import { FormErrorComponent } from '../../../../../utils/src/lib/components/form-error/form-error.component';
import { FormFieldComponent } from '../../../../../utils/src/lib/components/form-field/form-field.component';
import { LoadingButtonComponent } from '../../../../../utils/src/lib/components/loading-button/loading-button.component';
import { OAuthAbstractService } from '../../services/oauth/oauth-abstract.service';

enum AuthUIState {
  LOGIN,
  REGISTRATION,
  RESET_PASSWORD,
  CODE_VERIFICATION,
  NEW_PASSWORD,
}

@Component({
  selector: 'hae-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss'],
  standalone: true,
  imports: [
    MatDividerModule,
    ReactiveFormsModule,
    FormFieldComponent,
    MatFormFieldModule,
    FormErrorComponent,
    LoadingButtonComponent,
    MatButtonModule,
    MatIconModule,
    AsyncPipe,
    TranslateModule,
  ],
})
export class AuthComponent implements OnInit {
  @Input() oAuthService!: OAuthAbstractService;

  @Output() loggedIn = new EventEmitter<string | undefined>();

  @Output() registered = new EventEmitter<
    ReturnType<typeof this.registrationForm.getRawValue>
  >();

  $authUiState = signal<AuthUIState>(AuthUIState.LOGIN);

  loginForm = this.fb.group({
    username: ['', [Validators.required]],
    password: ['', [Validators.required]],
  });

  registrationForm = this.fb.group({
    username: ['', [Validators.required]],
    email: ['', [Validators.required]],
    password: ['', [Validators.required]],
  });

  resetPasswordForm = this.fb.group({ email: ['', [Validators.required]] });

  codeVerificationForm = this.fb.group({
    email: [
      {
        value: '',
        disabled: true,
      },
      [Validators.required],
    ],
    code: ['', [Validators.required]],
    password: '',
  });

  newPasswordForm = this.fb.group({
    email: '',
    newPassword: ['', [Validators.required]],
    code: ['', [Validators.required]],
  });

  $loading = signal(false);

  config$ = this.tenantStateService.getConfiguration();

  AuthUIState = AuthUIState;

  constructor(
    private activatedRoute: ActivatedRoute,
    private fb: NonNullableFormBuilder,
    private cognitoService: CognitoService,
    private tenantStateService: TenantStateService,
    private dialog: MatDialog,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.oAuthService.setSSOAction(() => {
      this.loggedIn.emit();
    });

    const { register } = this.activatedRoute.snapshot.queryParams;
    if (register) {
      this.changeAuthUIState(AuthUIState.REGISTRATION);
    }
  }

  login(): void {
    this.$loading.set(true);
    const { username, password } = this.loginForm.getRawValue();

    this.cognitoService.authenticate(username, password).subscribe({
      next: () => {
        this.loggedIn.emit();
      },
      error: (error) => {
        this.$loading.set(false);

        if (error.message === 'User is not confirmed.') {
          this.codeVerificationForm.patchValue({
            email: username,
            password,
          });
          this.changeAuthUIState(AuthUIState.CODE_VERIFICATION);
          return;
        }
        this.showError(error);
      },
    });
  }

  register(): void {
    this.$loading.set(true);

    const { email, username, password } = this.registrationForm.getRawValue();

    this.tenantStateService
      .getName()
      .pipe(
        mergeMap((name) => this.cognitoService.registerUser({
          email: username,
          password,
          customAttributes: [
            new CognitoUserAttribute({
              Name: 'email',
              Value: email,
            }),
            new CognitoUserAttribute({
              Name: 'custom:tenantName',
              Value: name,
            }),
          ],
        })),
      )
      .subscribe({
        next: () => {
          this.registered.emit(this.registrationForm.getRawValue());
          this.codeVerificationForm.patchValue({
            email: username,
            password,
          });
          this.changeAuthUIState(AuthUIState.CODE_VERIFICATION);
          this.$loading.set(false);
        },
        error: (error) => {
          this.showError(error);
          this.$loading.set(false);
        },
      });
  }

  resetPassword(): void {
    this.$loading.set(true);

    const { email } = this.resetPasswordForm.getRawValue();

    this.cognitoService.forgotPassword(email).subscribe({
      next: () => {
        this.newPasswordForm.reset({ email });
        this.changeAuthUIState(AuthUIState.NEW_PASSWORD);
        this.$loading.set(false);
      },
      error: (error) => {
        this.showError(error);
        this.$loading.set(false);
      },
    });
  }

  resendVerificationCode(): void {
    this.$loading.set(true);

    const { email } = this.codeVerificationForm.getRawValue();

    this.cognitoService.resendCode(email).subscribe({
      next: () => {
        this.$loading.set(false);
      },
      error: (error) => {
        this.$loading.set(false);
        this.showError(error);
      },
    });
  }

  verifyCode(): void {
    this.$loading.set(true);

    const { email, code, password } = this.codeVerificationForm.getRawValue();

    this.cognitoService
      .confirmRegistration(email, code)
      .pipe(mergeMap(() => this.cognitoService.authenticate(email, password)))
      .subscribe({
        next: () => {
          this.loggedIn.emit(email);
        },
        error: (error) => {
          this.showError(error);
          this.$loading.set(false);
        },
      });
  }

  setNewPassword(): void {
    this.$loading.set(true);

    const { email, newPassword, code } = this.newPasswordForm.getRawValue();

    this.cognitoService.confirmNewPassword(email, newPassword, code).subscribe({
      next: () => {
        this.changeAuthUIState(AuthUIState.LOGIN);
        this.$loading.set(false);
      },
      error: (error) => {
        this.showError(error);
        this.$loading.set(false);
      },
    });
  }

  changeAuthUIState(newState: AuthUIState): void {
    if (newState === AuthUIState.RESET_PASSWORD) {
      this.resetPasswordForm.reset();
    }
    this.$authUiState.set(newState);
  }

  private showError(error: Error): void {
    this.dialog.open(DialogComponent, {
      data: {
        title: 'Authentication Error',
        text: error.message,
      },
    });
  }

  showPassword(password: HTMLInputElement) {
    // eslint-disable-next-line no-param-reassign
    password.type = password.type === 'text' ? 'password' : 'text';
  }

  federatedLogin(loginProvider: string): void {
    this.oAuthService.login(loginProvider);
  }
}
