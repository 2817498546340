import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@app/modules/core/auth/services/auth/auth.service';
import {
  URL_PATH_HOME,
  URL_PATH_QUESTIONNAIRE,
  URL_PATH_WELCOME,
} from '@app/modules/core/routing/constants';
import {
  PageLoadedSuccessfully,
  TaskLoadedSuccessfully,
  TaskLoadingStarted,
  UserTransferFinished,
} from '@app/store/page/page.actions';
import { DonorControllerService, ProfileResponseDto } from '@hae/api';
import { PoolService, TokenTransferService } from '@hae/auth';
import { TranslateService } from '@ngx-translate/core';
import { Action, createSelector, State, StateContext } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { CognitoRefreshToken, CognitoUser } from 'amazon-cognito-identity-js';
import { Observable } from 'rxjs';
import {
  catchError, map, mergeMap, switchMap, tap,
} from 'rxjs/operators';

import { AUTH_STATE_TOKEN } from '../constants';
import {
  SaveUserDonorLookupData,
  UpdateBiometricsAcknowledgement,
  UpdateDonorLookupLanguage,
  UpdateReferralCode,
  UserLoggedSuccessfully,
  UserLogoutRequested,
  UserLookupLoadedSuccessfully,
  UserProfileLoadedSuccessfully,
  UserTransfer,
  UserTransferInQuestionnaire,
} from './auth.actions';
import { AuthState as AuthStateInterface } from './index.d';

@State({
  name: AUTH_STATE_TOKEN,
  defaults: {
    isUserTransfer: false,
    stateInitialized: false,
    biometricsAcknowledgement: false,
  },
})
@Injectable()
export class AuthState {
  constructor(
    private authService: AuthService,
    private donorControllerService: DonorControllerService,
    private location: Location,
    private router: Router,
    private tokenTransferService: TokenTransferService,
    private translateService: TranslateService,
    private poolService: PoolService,
  ) {}

  ngxsAfterBootstrap({ dispatch, setState }: StateContext<AuthStateInterface>) {
    this.authService.isUserLogged().subscribe((isUserLogged) => {
      const param = this.location.path();
      if (isUserLogged && !param.includes('transferToken')) {
        dispatch(new UserLoggedSuccessfully());
      }
      setState(patch<AuthStateInterface>({ stateInitialized: true }));
    });
  }

  @Action(UserLoggedSuccessfully)
  userLoggedSuccessfully({
    dispatch,
    getState,
  }: StateContext<AuthStateInterface>) {
    dispatch(new TaskLoadingStarted());

    return this.donorControllerService.getProfile().pipe(
      tap((user) => {
        dispatch(new UserProfileLoadedSuccessfully(user));
        const { referralCode } = getState();
        if (referralCode) {
          dispatch(new UpdateReferralCode(''));
          this.donorControllerService
            .submitReferralCode(referralCode)
            .subscribe();
        }
      }),
    );
  }

  @Action(UserLogoutRequested)
  userLogoutRequested(
    { setState, dispatch }: StateContext<AuthStateInterface>,
    action?: UserLogoutRequested,
  ) {
    let logoutUserObservable;

    if (action?.global) {
      logoutUserObservable = this.authService.logoutUser(action.global);
    } else {
      logoutUserObservable = this.authService.logoutUser();
    }

    return logoutUserObservable.pipe(
      tap(() => {
        setState(patch<AuthStateInterface>({ user: undefined }));
        dispatch(new PageLoadedSuccessfully());
      }),
      map(() => {
        this.router.navigate(['/', URL_PATH_WELCOME], { replaceUrl: true });
      }),
    );
  }

  @Action(UserTransfer)
  userTransfer(
    { setState, dispatch }: StateContext<AuthStateInterface>,
    { payload }: UserTransfer,
  ) {
    const { transferToken } = payload;
    return this.tokenTransferService.useTransferId(transferToken).pipe(
      catchError(() => this.donorControllerService.findRefreshTokenById(transferToken).pipe(
        mergeMap(({ refreshToken }) => this.poolService.getUserPool().pipe(
          mergeMap((userPool) => {
            const user = new CognitoUser({
              Username: '-',
              Pool: userPool,
            });
            return new Observable<void>((subscriber) => {
              user.refreshSession(
                new CognitoRefreshToken({ RefreshToken: refreshToken }),
                () => {
                  subscriber.next();
                  subscriber.complete();
                },
              );
            });
          }),
        )),
      )),
      tap(() => setState(patch<AuthStateInterface>({ isUserTransfer: true }))),
      switchMap(() => dispatch(new UserLoggedSuccessfully())),
    );
  }

  @Action(UserProfileLoadedSuccessfully)
  userProfileLoadedSuccessfully(
    { setState, dispatch }: StateContext<AuthStateInterface>,
    action: UserProfileLoadedSuccessfully,
  ) {
    setState(patch<AuthStateInterface>({ user: action.payload }));

    if (action.payload.userType === ProfileResponseDto.UserTypeEnum.Donor) {
      this.donorControllerService
        .getLookupDonor()
        .pipe(
          map((donorLookupResult) => dispatch(new UserLookupLoadedSuccessfully(donorLookupResult))),
        )
        .subscribe();
      return;
    }
    dispatch(new TaskLoadedSuccessfully());
    setState(patch<AuthStateInterface>({ donorLookup: undefined }));

    this.router.navigate(['/', URL_PATH_HOME]);
  }

  @Action(UserLookupLoadedSuccessfully)
  userLookupLoadedSuccessfully(
    { setState, dispatch, getState }: StateContext<AuthStateInterface>,
    action: UserLookupLoadedSuccessfully,
  ) {
    if (action.payload === false) return;
    const donorLookup = action.payload;
    const { isUserTransfer } = getState();

    setState(
      patch<AuthStateInterface>({
        donorLookup: {
          ...donorLookup,
          locale: donorLookup.locale ?? this.translateService.currentLang,
        },
      }),
    );

    dispatch(new TaskLoadedSuccessfully());
    dispatch(new UserTransferFinished());

    this.router.navigate([
      '/',
      isUserTransfer ? URL_PATH_QUESTIONNAIRE : URL_PATH_HOME,
    ]);
  }

  @Action(UpdateDonorLookupLanguage)
  updateDonorLookupLanguage(
    { getState, setState }: StateContext<AuthStateInterface>,
    action: UpdateDonorLookupLanguage,
  ) {
    const { donorLookup } = getState();
    if (donorLookup) {
      setState(
        patch<AuthStateInterface>({
          donorLookup: {
            ...donorLookup,
            locale: action.payload,
          },
        }),
      );
    }
  }

  @Action(SaveUserDonorLookupData)
  saveUserDonorLookupData(
    { setState }: StateContext<AuthStateInterface>,
    action: SaveUserDonorLookupData,
  ) {
    setState(
      patch<AuthStateInterface>({ userDonorLookupData: action.payload }),
    );
  }

  @Action(UpdateBiometricsAcknowledgement)
  updateBiometricsAcknowledgement(
    { setState }: StateContext<AuthStateInterface>,
    action: { payload: boolean },
  ) {
    setState(
      patch<AuthStateInterface>({ biometricsAcknowledgement: action.payload }),
    );
  }

  @Action(UpdateReferralCode)
  updateReferralCode(
    { setState }: StateContext<AuthStateInterface>,
    action: { payload: string },
  ) {
    setState(patch<AuthStateInterface>({ referralCode: action.payload }));
  }

  @Action(UserTransferInQuestionnaire)
  userTransferInQuestionnaire({ setState }: StateContext<AuthStateInterface>) {
    setState(patch<AuthStateInterface>({ isUserTransfer: false }));
  }
}

export class AuthStateSelectors {
  static isUserTransfer = createSelector(
    [AuthState],
    (state: AuthStateInterface) => state.isUserTransfer,
  );

  static user = createSelector(
    [AuthState],
    (state: AuthStateInterface) => state.user,
  );

  static stateInitialized = createSelector(
    [AuthState],
    (state: AuthStateInterface) => state.stateInitialized,
  );

  static donorLookup = createSelector(
    [AuthState],
    (state: AuthStateInterface) => state.donorLookup,
  );

  static userDonorLookupData = createSelector(
    [AuthState],
    (state: AuthStateInterface) => state.userDonorLookupData,
  );

  static biometricsAcknowledgement = createSelector(
    [AuthState],
    (state: AuthStateInterface) => state.biometricsAcknowledgement,
  );

  static donorOnBoardingRequired = createSelector(
    [AuthState],
    (state: AuthStateInterface) => state.user?.donorStatus
      === ProfileResponseDto.DonorStatusEnum.OnboardRequired,
  );
}
