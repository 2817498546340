@if (config$ | async; as config) {
  <div class="auth-container">
    <div class="flow-container">
      @switch ($authUiState()) {
        <!--LOGIN FLOW-->
        @case (AuthUIState.LOGIN) {
          <h1>{{ "auth.sign_in_header" | translate }}</h1>
          @if (config.googleLogin === 'true') {
            <button
              (click)="federatedLogin('Google')"
              class="social-login remove-padding"
              >
              <span class="social-icon google-icon-container">
                <svg
                  preserveAspectRatio="xMidYMid"
                  viewBox="0 0 256 262"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  >
                  <path
                    d="M255.878 133.451c0-10.734-.871-18.567-2.756-26.69H130.55v48.448h71.947c-1.45 12.04-9.283 30.172-26.69 42.356l-.244 1.622 38.755 30.023 2.685.268c24.659-22.774 38.875-56.282 38.875-96.027"
                    fill="#4285F4"
                  ></path>
                  <path
                    d="M130.55 261.1c35.248 0 64.839-11.605 86.453-31.622l-41.196-31.913c-11.024 7.688-25.82 13.055-45.257 13.055-34.523 0-63.824-22.773-74.269-54.25l-1.531.13-40.298 31.187-.527 1.465C35.393 231.798 79.49 261.1 130.55 261.1"
                    fill="#34A853"
                  ></path>
                  <path
                    d="M56.281 156.37c-2.756-8.123-4.351-16.827-4.351-25.82 0-8.994 1.595-17.697 4.206-25.82l-.073-1.73L15.26 71.312l-1.335.635C5.077 89.644 0 109.517 0 130.55s5.077 40.905 13.925 58.602l42.356-32.782"
                    fill="#FBBC05"
                  ></path>
                  <path
                    d="M130.55 50.479c24.514 0 41.05 10.589 50.479 19.438l36.844-35.974C195.245 12.91 165.798 0 130.55 0 79.49 0 35.393 29.301 13.925 71.947l42.211 32.783c10.59-31.477 39.891-54.251 74.414-54.251"
                    fill="#EB4335"
                  ></path>
                </svg>
              </span>
              <span class="button-text">{{
                "auth.federated.google" | translate
              }}</span>
            </button>
          }
          @if (config.facebookLogin === 'true') {
            <button
              (click)="federatedLogin('Facebook')"
              class="social-login facebook"
              >
              <span class="social-icon">
                <svg
                  viewBox="0 0 279 538"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="24"
                  >
                  <g fill="none">
                    <g fill="#FFF">
                      <path
                        d="M82.3409742,538 L82.3409742,292.936652 L0,292.936652 L0,196.990154 L82.2410458,196.990154 L82.2410458,126.4295 C82.2410458,44.575144 132.205229,0 205.252865,0 C240.227794,0 270.306232,2.59855099 279,3.79788222 L279,89.2502322 L228.536175,89.2502322 C188.964542,89.2502322 181.270057,108.139699 181.270057,135.824262 L181.270057,196.89021 L276.202006,196.89021 L263.810888,292.836708 L181.16913,292.836708 L181.16913,538 L82.3409742,538 Z"
                      ></path>
                    </g>
                  </g>
                </svg>
              </span>
              <span class="button-text">{{
                "auth.federated.facebook" | translate
              }}</span>
            </button>
          }
          @if (config.appleLogin === 'true') {
            <button
              (click)="federatedLogin('SignInWithApple')"
              class="social-login apple"
              >
              <span class="social-icon">
                <svg
                  fill="none"
                  height="32"
                  viewBox="0 0 32 32"
                  width="32"
                  xmlns="http://www.w3.org/2000/svg"
                  >
                  <path
                    d="M21.8206 8.55371C19.3006 8.55371 18.2356 9.75621 16.4806 9.75621C14.6812 9.75621 13.3087 8.56246 11.125 8.56246C8.98749 8.56246 6.70812 9.86746 5.26062 12.0906C3.22812 15.2256 3.57312 21.13 6.86499 26.16C8.04249 27.9606 9.61499 29.98 11.6775 30.0018H11.715C13.5075 30.0018 14.04 28.8281 16.5069 28.8143H16.5444C18.9744 28.8143 19.4619 29.995 21.2469 29.995H21.2844C23.3469 29.9731 25.0037 27.7356 26.1812 25.9418C27.0287 24.6518 27.3437 24.0043 27.9937 22.545C23.2319 20.7375 22.4669 13.9868 27.1762 11.3987C25.7387 9.59871 23.7187 8.55621 21.8144 8.55621L21.8206 8.55371Z"
                    fill="white"
                    />
                  <path
                    d="M21.2656 2C19.7656 2.10187 18.0156 3.05687 16.9906 4.30375C16.0606 5.43375 15.2956 7.11 15.5956 8.73563H15.7156C17.3131 8.73563 18.9481 7.77375 19.9031 6.54125C20.8231 5.36813 21.5206 3.70563 21.2656 2Z"
                    fill="white"
                    />
                </svg>
              </span>
              <span class="button-text">{{
                "auth.federated.apple" | translate
              }}</span>
            </button>
          }
          @if (config.openIDLogin !== 'false') {
            <button
              (click)="federatedLogin(config.openIDLogin.toString())"
              class="social-login"
              >
              <span class="button-text">{{
                "loginPage.openIDLogin" | translate
              }}</span>
            </button>
          }
          @if (config.SAMLLogin !== 'false') {
            <button
              (click)="federatedLogin(config.SAMLLogin.toString())"
              class="social-login"
              >
              <span class="button-text">{{ "loginPage.SAMLLogin" | translate }}</span>
            </button>
          }
          @if (config.usernameLogin === 'true') {
            @if (
              config.googleLogin === 'true' ||
              config.facebookLogin === 'true' ||
              config.appleLogin === 'true' ||
              config.openIDLogin !== 'false' ||
              config.SAMLLogin !== 'false'
              ) {
              <div
                class="text-with-line"
                >
                <mat-divider></mat-divider>
                <span>{{ "loginPage.or" | translate }}</span>
                <mat-divider></mat-divider>
              </div>
            }
            <ng-content select="[login]"></ng-content>
            <form (ngSubmit)="login()" [formGroup]="loginForm">
              <hae-form-field>
                <mat-label class="label">{{
                  "auth.login_name_label" | translate
                }}</mat-label>
                <input
                  autofocus
                  class="input-amplify"
                  formControlName="username"
                  placeholder="{{ 'auth.login_name' | translate }}"
                  type="email"
                  />
                <hae-form-error control="username">{{
                  "login.form.fieldRequired" | translate
                }}</hae-form-error>
              </hae-form-field>
              <hae-form-field>
                <mat-label class="label">{{
                  "auth.password_label" | translate
                }}</mat-label>
                <input
                  class="input-amplify"
                  formControlName="password"
                  placeholder="{{ 'auth.password' | translate }}"
                  type="password"
                  />
                <hae-form-error control="password">{{
                  "login.form.fieldRequired" | translate
                }}</hae-form-error>
              </hae-form-field>
              <div>
                <span class="center"
                  >{{ "auth.forgot_password" | translate }}
                  <span (click)="changeAuthUIState(2)" class="link">{{
                    "auth.reset_password" | translate
                  }}</span>
                </span>
              </div>
              <div class="register-container">
                <span
                  >{{ "auth.no_account" | translate }}
                  <span (click)="changeAuthUIState(1)" class="link">{{
                    "auth.create_account" | translate
                  }}</span>
                </span>
                <hae-loading-button
                  [loading]="$loading()"
                  class="login-button"
                  >
                  <button
                    [disabled]="loginForm.invalid"
                    class="accept-button"
                    color="primary"
                    mat-raised-button
                    type="submit"
                    >
                    {{ "auth.sign_in" | translate }}
                  </button>
                </hae-loading-button>
              </div>
            </form>
          }
        }
        <!--REGISTRATION FLOW-->
        @case (AuthUIState.REGISTRATION) {
          <h1>{{ "auth.register_title" | translate }}</h1>
          <form (ngSubmit)="register()" [formGroup]="registrationForm">
            <ng-content select="[register]"></ng-content>
            <hae-form-field>
              <input
                class="input-amplify"
                formControlName="email"
                placeholder="{{ 'auth.email' | translate }}"
                type="email"
                />
              <hae-form-error control="email">{{
                "login.form.fieldRequired" | translate
              }}</hae-form-error>
            </hae-form-field>
            <hae-form-field>
              <input
                class="input-amplify"
                formControlName="username"
                placeholder="{{ 'auth.username' | translate }}"
                />
              <hae-form-error control="username">{{
                "login.form.fieldRequired" | translate
              }}</hae-form-error>
            </hae-form-field>
            <hae-form-field>
              <div class="show-password">
                <input
                  #password
                  class="input-amplify"
                  formControlName="password"
                  placeholder="{{ 'auth.password' | translate }}"
                  type="password"
                  />
                <mat-icon (click)="showPassword(password)">{{
                  password.type === "text" ? "visibility_off" : "visibility"
                }}</mat-icon>
              </div>
              <hae-form-error control="password">{{
                "login.form.fieldRequired" | translate
              }}</hae-form-error>
            </hae-form-field>
            <hae-loading-button [loading]="$loading()">
              <button
                [disabled]="registrationForm.invalid"
                class="accept-button"
                color="primary"
                mat-raised-button
                type="submit"
                >
                {{ "auth.create_account" | translate }}
              </button>
            </hae-loading-button>
          </form>
          <span
            >{{ "auth.have_account" | translate }}
            <span (click)="changeAuthUIState(0)" class="link">{{
              "auth.sign_in" | translate
            }}</span>
          </span>
        }
        <!--CODE VERIFICATION-->
        @case (AuthUIState.CODE_VERIFICATION) {
          <h1>{{ "auth.confirm_signup" | translate }}</h1>
          <div class="description">
            {{ "auth.confirm_text1" | translate }}
            {{ "auth.confirm_text2" | translate }}
          </div>
          <form (ngSubmit)="verifyCode()" [formGroup]="codeVerificationForm">
            <hae-form-field>
              <input
                class="input-amplify"
                formControlName="email"
                placeholder="{{ 'auth.email' | translate }}"
                type="text"
                />
              <hae-form-error control="email">{{
                "login.form.fieldRequired" | translate
              }}</hae-form-error>
            </hae-form-field>
            <hae-form-field>
              <input
                class="input-amplify"
                formControlName="code"
                placeholder="{{ 'auth.confirmation_code' | translate }}"
                numeric
                />
              <hae-form-error control="code">{{
                "login.form.fieldRequired" | translate
              }}</hae-form-error>
            </hae-form-field>
            <span>
              {{ "auth.lost_code" | translate }}
              <span (click)="resendVerificationCode()" class="link">{{
                "auth.resend_code" | translate
              }}</span>
            </span>
            <hae-loading-button [loading]="$loading()">
              <button
                [disabled]="codeVerificationForm.invalid"
                class="accept-button"
                color="primary"
                mat-raised-button
                type="submit"
                >
                {{ "auth.send_code" | translate }}
              </button>
            </hae-loading-button>
          </form>
          <span (click)="changeAuthUIState(0)" class="link">{{
            "auth.back_to_signin" | translate
          }}</span>
        }
        <!--FORGOT PASSWORD FLOW-->
        @case (AuthUIState.RESET_PASSWORD) {
          <h1>{{ "auth.forgot_title" | translate }}</h1>
          <form (ngSubmit)="resetPassword()" [formGroup]="resetPasswordForm">
            <hae-form-field>
              <input
                class="input-amplify"
                formControlName="email"
                placeholder="{{ 'auth.email' | translate }}"
                type="text"
                />
              <hae-form-error control="email">{{
                "login.form.fieldRequired" | translate
              }}</hae-form-error>
            </hae-form-field>
            <hae-loading-button [loading]="$loading()">
              <button
                [disabled]="resetPasswordForm.invalid"
                class="accept-button"
                color="primary"
                mat-raised-button
                type="submit"
                >
                {{ "auth.send_code" | translate }}
              </button>
            </hae-loading-button>
          </form>
          <span (click)="changeAuthUIState(0)" class="link">{{
            "auth.back_to_signin" | translate
          }}</span>
        }
        <!--NEW PASSWORD FLOW-->
        @case (AuthUIState.NEW_PASSWORD) {
          <h1>{{ "auth.forgot_title" | translate }}</h1>
          <form (ngSubmit)="setNewPassword()" [formGroup]="newPasswordForm">
            <hae-form-field>
              <input
                class="input-amplify"
                formControlName="code"
                placeholder="{{ 'auth.code' | translate }}"
                numeric
                />
              <hae-form-error control="code">{{
                "login.form.fieldRequired" | translate
              }}</hae-form-error>
            </hae-form-field>
            <hae-form-field>
              <div class="show-password">
                <input
                  #newPassword
                  class="input-amplify"
                  formControlName="newPassword"
                  placeholder="{{ 'auth.new_password' | translate }}"
                  type="password"
                  />
                <mat-icon (click)="showPassword(newPassword)">{{
                  newPassword.type === "text" ? "visibility_off" : "visibility"
                }}</mat-icon>
              </div>
              <hae-form-error control="newPassword"
                >{{ "login.form.fieldRequired" | translate }}
              </hae-form-error>
            </hae-form-field>
            <hae-loading-button [loading]="$loading()">
              <button
                [disabled]="newPasswordForm.invalid"
                class="accept-button"
                color="primary"
                mat-raised-button
                type="submit"
                >
                {{ "auth.submit" | translate }}
              </button>
            </hae-loading-button>
          </form>
          <span (click)="changeAuthUIState(0)" class="link">{{
            "auth.back_to_signin" | translate
          }}</span>
        }
      }
    </div>
  </div>
}
